import { useModal } from "@/hooks/useModal";
import { createContext, useContext } from "react";

interface InviteModalContextType {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

const InviteModalContext = createContext<InviteModalContextType | undefined>(
  undefined
);

export const InviteModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const modal = useModal();

  return (
    <InviteModalContext.Provider value={modal}>
      {children}
    </InviteModalContext.Provider>
  );
};

export const useInviteModal = () => {
  const context = useContext(InviteModalContext);
  if (!context) {
    throw new Error(
      "useInviteModal must be used within an InviteModalProvider"
    );
  }
  return context;
};
