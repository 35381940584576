import { Auth } from "@/hooks/auth";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ChevronUpDownIcon,
  Cog8ToothIcon,
  ArrowRightEndOnRectangleIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/24/solid";
import { useAppDispatch } from "@/hooks/redux";
import { useEffect, useState } from "react";
import { resetAuthUiState } from "@/slices/auth/authUi";
import { supabase } from "@/utilities/supabase";
import { setActiveTeam, setIsOwner } from "@/slices/team/teamUi";
import { useGetCompaniesQuery } from "@/slices/company/companyApi";
import { useGetUserByIdQuery } from "@/slices/users/usersApi";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { AvatarWithFallback } from "@/components/Avatar";
import Dropdown from "@/components/Dropdown";
import { Tag } from "@/components/Tag";
import { InviteModalProvider } from "@/components/InviteModal/InviteModalContext";

export const PlatformWithTopNav = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [team, setTeam]: any = useState(null);
  const [userId, setUserId]: any = useState(null);

  /* API */
  const { data: userRes, isFetching: userResFetching } = useGetUserByIdQuery(
    userId,
    { skip: !userId }
  );

  const { data: companyRes, isFetching: isCompanyResFetching } =
    useGetCompaniesQuery({});

  useEffect(() => {
    const getUser = async () => {
      const { data } = await supabase.auth.getSession();
      setUserId(data?.session?.user?.id || null);
    };
    getUser();
  }, []);

  useEffect(() => {
    if (companyRes?.data?.[0]?.id && userId) {
      const activeTeam = companyRes.data[0];
      dispatch(setActiveTeam(activeTeam));
      setTeam(activeTeam);
      dispatch(setIsOwner(activeTeam.ownerId === userRes?.person?.id));
    }
  }, [userRes, companyRes, dispatch]);

  const logout = async () => {
    await supabase.auth.signOut();
    navigate("/login");
    dispatch(resetAuthUiState());
  };

  const menuItems = [
    { name: "Dashboard", path: "/" },
    { name: "Relationships", path: "/relationships" },
    { name: "Deals", path: "/deals" },
    { name: "Templates", path: "/templates" },
    { name: "Documents", path: "/documents" },
  ];
  // Determine the background dynamically
  const isSettingsPage = location.pathname.startsWith("/settings");
  const isDashboardPage =
    location.pathname === "/" || location.pathname === "/dashboard";

  // const backgroundClass = isDashboardPage
  //   ? "bg-zinc-50" // used to be bg-gradient-to-br from-[#181EAE1A] to-[#FFFFFF00]
  //   : isSettingsPage
  //   ? "bg-zinc-50"
  //   : "bg-[#FAFAFA]";

  return (
    <Auth>
      <div className={cn("min-h-screen")}>
        <header className="border-b border-gray-200 bg-white px-6 py-4 flex justify-between items-center sticky top-0 z-50 overflow-x-auto">
          <div className="flex items-center space-x-6 mr-6">
            <a href="/">
              <img src="/repsity-icon.svg" alt="Logo" className="w-6 h-auto" />
            </a>
            {menuItems.map((item, index) => (
              <a
                key={index}
                className={cn(
                  "text-sm font-medium hover:cursor-pointer",
                  location.pathname === item.path
                    ? "text-zinc-950 hover:text-zinc-950"
                    : "text-zinc-500 hover:text-zinc-500"
                )}
                onClick={() => navigate(item.path)}
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="flex items-center space-x-4">
            {/* Team Dropdown */}
            <Dropdown
              className="min-w-[200px] hidden md:block"
              items={[
                {
                  text: (
                    <div className="grid grid-cols-[16px,1fr] gap-3">
                      <Cog8ToothIcon className="h-5 w-5 mr-2" />
                      Workspace Settings
                    </div>
                  ),
                  onClick: () => navigate("/settings/workspace"),
                },
                {
                  text: (
                    <div>
                      <div className="grid grid-cols-[16px,1fr] gap-3">
                        <BuildingOfficeIcon className="h-5 w-5 mr-2" />
                        Add another workspace
                      </div>
                      <Tag
                        name="Coming soon"
                        className="ml-7 mt-1 bg-emerald-600/10 text-emerald-600 border border-emerald-600 w-fit inline-block hover:bg-emerald-600/10"
                      />
                    </div>
                  ),
                },
              ]}
              buttonText={
                isCompanyResFetching
                  ? "Loading..."
                  : team?.name || "Personal Team"
              }
              buttonColor="white"
              icon="chevronUpDown"
              iconOnRight={true}
            />

            {/* User Dropdown */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 rounded-full">
                  <AvatarWithFallback
                    className="h-8 w-8"
                    image={userRes?.person?.avatarRef}
                    emptyImageText={
                      userRes?.person?.name
                        ?.split(" ")
                        .slice(0, 2)
                        .map((n: any) => n[0])
                        .join("") || `${" "}`
                    }
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>
                  <div className="flex flex-col text-sm">
                    <span>{userRes?.person?.name}</span>
                    <span className="text-xs text-gray-500">
                      {userRes?.email}
                    </span>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    onClick={() => navigate("/settings/profile")}
                  >
                    <Cog8ToothIcon className="h-5 w-5 mr-2" />
                    Settings
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={logout}>
                    <ArrowRightEndOnRectangleIcon className="h-5 w-5 mr-2" />
                    Sign Out
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </header>
        <InviteModalProvider>
          <main className="p-8 pt-6">
            <Outlet />
          </main>
        </InviteModalProvider>
      </div>
    </Auth>
  );
};
