import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const documentsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "documentsApi",
    baseUrl: "/api/documents",
  }),
  reducerPath: "documentsApi",
  tagTypes: ["Document", "Document History"],
  endpoints: (builder) => ({
    getDocuments: builder.query<
      any,
      { include?: string; search?: string; nextId?: string; limit?: number }
    >({
      query: (args) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        // Append parameters if they exist
        if (args.search) queryParams.append("query", args.search);
        if (args.include) queryParams.append("include", args.include);
        if (args.limit) queryParams.append("limit", args.limit.toString());
        if (args.nextId) queryParams.append("nextId", args.nextId);
        return `?${queryParams.toString()}`;
      },
      keepUnusedDataFor: 0,
    }),
    getDocumentById: builder.query<
      any,
      {
        id?: string;
        include?: string;
        personId?: string;
        invitationId?: string;
      }
    >({
      query: (args) => {
        const { id, include, personId, invitationId } = args;

        // Include `personId` and `invitationId` as query parameters if present
        const queryParams = new URLSearchParams();
        if (include) queryParams.append("include", include);
        if (personId) queryParams.append("personId", personId);
        if (invitationId) queryParams.append("invitationId", invitationId);

        return `/${id}?${queryParams.toString()}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Document" }],
    }),
    getDocumentFieldsById: builder.query<any, { id?: string }>({
      query: (args) => `/${args.id}/fields`,
      keepUnusedDataFor: 0,
    }),
    getDocumentHistory: builder.query<any, { id: string }>({
      query: (args) => `/${args.id}/history`,
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Document History" }],
    }),
    getDocumentHistoryById: builder.query<
      any,
      { id: string; historyId?: string }
    >({
      query: (args) => `/${args.id}/history/${args.historyId}`,
      keepUnusedDataFor: 0,
    }),
    patchDocumentById: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    updateDocumentById: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    publishDocumentById: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/publish`,
        method: "POST",
        body: data,
      }),
    }),
    getFieldUses: builder.query<any, { documentId: string; fieldId: string }>({
      query: (args) => `${args.documentId}/fields/${args.fieldId}/uses`,
      keepUnusedDataFor: 0,
    }),
    addDocumentParticipants: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/participants`,
        method: "POST",
        body: data,
      }),
    }),
    updateDocumentParticipants: builder.mutation<
      any,
      { id: string; pId: string; data: any }
    >({
      query: ({ id, pId, data }) => ({
        url: `/${id}/participants/${pId}`,
        method: "PUT",
        body: data,
      }),
    }),
    removeDocumentParticipants: builder.mutation<
      any,
      { id: string; personId: string }
    >({
      query: ({ id, personId }) => ({
        url: `/${id}/participants/${personId}`,
        method: "DELETE",
      }),
    }),
    updateDocumentAssignee: builder.mutation<
      any,
      { id: string; teamId: string }
    >({
      query: ({ id, teamId }) => ({
        url: `/${id}/assignee`,
        method: "PUT",
        body: { id: teamId },
      }),
    }),
    deleteDocumentAssignee: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}/assignee`,
        method: "DELETE",
      }),
    }),
    deleteDocument: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
    updateDocumentSignature: builder.mutation<
      any,
      { id: string; body: any; personId?: string; invitationId?: string }
    >({
      query: ({ id, body, personId, invitationId }) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        if (personId) queryParams.append("personId", personId);
        if (invitationId) queryParams.append("invitationId", invitationId);

        return {
          url: `/${id}/signature?${queryParams.toString()}`, // Append query parameters to the URL
          method: "POST",
          body,
        };
      },
    }),

    updateDocumentField: builder.mutation<
      any,
      {
        documentId: string;
        fieldId: string;
        body: any;
        updateWorkflow?: boolean;
      }
    >({
      query: ({ documentId, fieldId, body, updateWorkflow = false }) => ({
        url: `/${documentId}/fields/${fieldId}${
          updateWorkflow ? `?updateWorkflow=${updateWorkflow}` : ""
        }`,
        method: "PUT",
        body,
      }),
    }),

    updateDocumentFieldsBulk: builder.mutation<
      any,
      {
        documentId: string;
        body: any[];
        updateWorkflow?: boolean;
      }
    >({
      query: ({ documentId, body, updateWorkflow = false }) => ({
        url: `/${documentId}/fields${
          updateWorkflow ? `?updateWorkflow=${updateWorkflow}` : ""
        }`,
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentByIdQuery,
  useGetDocumentFieldsByIdQuery,
  useGetDocumentHistoryQuery,
  useGetDocumentHistoryByIdQuery,
  usePatchDocumentByIdMutation,
  useUpdateDocumentByIdMutation,
  usePublishDocumentByIdMutation,
  useGetFieldUsesQuery,
  useAddDocumentParticipantsMutation,
  useUpdateDocumentParticipantsMutation,
  useRemoveDocumentParticipantsMutation,
  useUpdateDocumentAssigneeMutation,
  useDeleteDocumentAssigneeMutation,
  useDeleteDocumentMutation,
  useUpdateDocumentSignatureMutation,
  useUpdateDocumentFieldMutation,
  useUpdateDocumentFieldsBulkMutation,
} = documentsApi;
