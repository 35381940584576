import { Heading } from "@/components/catalyst/heading";
import { Separator } from "@/components/ui/separator";

export const Header = ({
  title = "",
  subtitle = "",
  actions = <></>,
  className = "",
  headingClassName = "",
  subtitleClassName = "",
  hasSeparator = false,
}) => {
  return (
    <div className={`w-full max-w-[1120px] mx-auto ${className}`}>
      <div className="flex w-full flex-wrap items-end justify-between gap-4">
        <div className="flex flex-col gap-1">
          <Heading className={headingClassName}>{title}</Heading>
          {subtitle && (
            <p
              className={`text-base text-neutral-500 font-normal leading-5 tracking-tight max-w-[335px] ${subtitleClassName}`}
            >
              {subtitle}
            </p>
          )}
        </div>
        <div className="flex gap-4">{actions}</div>
      </div>
      {hasSeparator && <Separator className="bg-gray-200 mt-6" />}
    </div>
  );
};
