import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const teamApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "teamApi",
    baseUrl: "/api/team",
  }),
  reducerPath: "teamApi",
  tagTypes: ["Team"],
  endpoints: (builder) => ({
    getTeam: builder.query<any, { documentId?: string }>({
      query: (args) => {
        const queryParams = args.documentId
          ? `?documentId=${args.documentId}`
          : "";
        return `${queryParams}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Team" }],
    }),
    getTeamById: builder.query<any, { id: string; include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Team" }],
    }),
    updateTeam: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: { teamData: data },
      }),
    }),
    addTeamMember: builder.mutation<any, { id: string; personId: string }>({
      query: ({ id, personId }) => ({
        url: `/${id}/person`,
        method: "POST",
        body: { teamId: id, personId },
      }),
    }),
    deleteTeamMember: builder.mutation<any, { id: string; personId: string }>({
      query: ({ id, personId }) => ({
        url: `/${id}/person/${personId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetTeamQuery,
  useGetTeamByIdQuery,
  useUpdateTeamMutation,
  useAddTeamMemberMutation,
  useDeleteTeamMemberMutation,
} = teamApi;
