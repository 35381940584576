import React from "react";
import { Badge } from "./ui/badge";

type TagProps = {
  name: string;
  className?: string;
};

// Helpers
const getColor = (name: string) => {
  switch (name) {
    case "Complete":
      return "bg-emerald-600/10 text-zinc-950 border border-zinc-200 govuk-tag--green hover:bg-emerald-600/10";
    case "Needs Action":
      return "bg-[#F1E783]/50 text-zinc-950 border border-zinc-200 hover:bg-[#F1E783]/50";
    case "Ready for Signature":
      return "bg-blue-800/10 text-zinc-950 border border-zinc-200 hover:bg-blue-800/10";
    case "In Review":
      return "bg-[#F1E783]/50 text-zinc-950 border border-zinc-200 hover:bg-[#F1E783]/50";
    default:
      return "bg-indigo-700/10 text-zinc-950 border border-zinc-200 hover:bg-indigo-700/10";
  }
};

export const Tag: React.FC<TagProps> = ({ name, className = "" }) => {
  return (
    <Badge
      variant="default"
      className={`flex items-center gap-1 text-center text-xs font-semibold rounded-full py-0.5 px-2.5 shadow-sm whitespace-nowrap w-fit ${getColor(
        name
      )} ${className}`}
    >
      {name}
    </Badge>
  );
};
