import { Auth } from "@/hooks/auth";
import { Outlet } from "react-router-dom";
import { Flex } from "@/components/Flex";
import { InviteModalProvider } from "@/components/InviteModal/InviteModalContext";

export const Platform = () => {
  return (
    <Auth>
      <InviteModalProvider>
        <Flex direction="row">
          <div className="flex-1">
            <Outlet />
          </div>
        </Flex>
      </InviteModalProvider>
    </Auth>
  );
};
