import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const companyApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "companyApi",
    baseUrl: "/api/companies",
  }),
  reducerPath: "companyApi",
  tagTypes: ["Workspace", "Workspaces"],
  endpoints: (builder) => ({
    getCompanies: builder.query<any, { search?: string }>({
      query: (args) => {
        const queryParams = args.search ? `?search=${args.search}` : "";
        return `/`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Workspaces" }],
    }),
    getCompanyById: builder.query<any, { id: string }>({
      query: (args) => {
        return `/${args.id}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Workspace" }],
    }),
    updateCompany: builder.mutation<
      any,
      { id: string; company: { name?: string; website?: string; tags?: any } }
    >({
      query: (data) => ({
        url: `/${data.id}`,
        method: "PUT",
        body: data.company,
      }),
    }),
    createCompanyAddress: builder.mutation<
      any,
      {
        id: string;
        address: {
          addressLine1: string;
          city: string;
          state: any;
          postalCode: string;
          country: string;
        };
      }
    >({
      query: (data) => ({
        url: `/${data.id}/address`,
        method: "POST",
        body: data.address,
      }),
    }),
    updateCompanyAddress: builder.mutation<
      any,
      {
        id: string;
        addressId: string;
        address: {
          addressLine1: string;
          city: string;
          state: any;
          postalCode: string;
          country: string;
        };
      }
    >({
      query: (data) => ({
        url: `/${data.id}/address/${data.addressId}`,
        method: "PUT",
        body: data.address,
      }),
    }),
    deleteCompanyAddress: builder.mutation<
      any,
      { companyId: string; addressId: string }
    >({
      query: ({ companyId, addressId }) => ({
        url: `/${companyId}/address/${addressId}`,
        method: "DELETE",
      }),
    }),
    createCompanyPhone: builder.mutation<
      any,
      {
        id: string;
        phoneNumber: string;
      }
    >({
      query: (data) => ({
        url: `/${data.id}/phone`,
        method: "POST",
        body: { phoneNumber: data.phoneNumber },
      }),
    }),
    updateCompanyPhone: builder.mutation<
      any,
      {
        id: string;
        phoneId: string;
        phoneNumber: string;
      }
    >({
      query: (data) => ({
        url: `/${data.id}/phone/${data.phoneId}`,
        method: "PUT",
        body: { phoneNumber: data.phoneNumber },
      }),
    }),
    deleteCompanyPhone: builder.mutation<
      any,
      { companyId: string; phoneId: string }
    >({
      query: ({ companyId, phoneId }) => ({
        url: `/${companyId}/phone/${phoneId}`,
        method: "DELETE",
      }),
    }),
    createCompanyEmail: builder.mutation<
      any,
      {
        id: string;
        emailAddress: string;
      }
    >({
      query: (data) => ({
        url: `/${data.id}/email`,
        method: "POST",
        body: { emailAddress: data.emailAddress },
      }),
    }),
    updateCompanyEmail: builder.mutation<
      any,
      {
        id: string;
        emailId: string;
        emailAddress: string;
      }
    >({
      query: (data) => ({
        url: `/${data.id}/email/${data.emailId}`,
        method: "PUT",
        body: { emailAddress: data.emailAddress },
      }),
    }),
    deleteCompanyEmail: builder.mutation<
      any,
      { companyId: string; emailId: string }
    >({
      query: ({ companyId, emailId }) => ({
        url: `/${companyId}/email/${emailId}`,
        method: "DELETE",
      }),
    }),
    createCompany: builder.mutation<any, { companyData: any }>({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data.companyData,
      }),
    }),
    createCompanyMember: builder.mutation<
      any,
      { companyId: string; personId: string; role?: string }
    >({
      query: (data) => ({
        url: `/${data.companyId}/members`,
        method: "POST",
        body: {
          personId: data.personId,
          ...(data.role && { role: data.role || "MEMBER" }),
        },
      }),
    }),
    deleteCompanyMember: builder.mutation<
      any,
      { id: string; personId: string }
    >({
      query: ({ id, personId }) => ({
        url: `/${id}/members/${personId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyByIdQuery,
  useLazyGetCompanyByIdQuery,
  useUpdateCompanyMutation,
  useCreateCompanyAddressMutation,
  useUpdateCompanyAddressMutation,
  useDeleteCompanyAddressMutation,
  useCreateCompanyPhoneMutation,
  useUpdateCompanyPhoneMutation,
  useDeleteCompanyPhoneMutation,
  useCreateCompanyEmailMutation,
  useUpdateCompanyEmailMutation,
  useDeleteCompanyEmailMutation,
  useCreateCompanyMutation,
  useCreateCompanyMemberMutation,
  useDeleteCompanyMemberMutation,
} = companyApi;
